import { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import logo from "./../../Images/WTMCase.svg";
import results from "./../../Images/WTMResults.svg";
import ipad from "./../../Images/WTMipad.svg";

function WTM() {
  const [loading, setLoading]: [boolean, (value: (((prevState: boolean) => boolean) | boolean)) => void] = useState(false);
  useEffect((): void => {
    setLoading(true);
    setTimeout((): void => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <div className="flex justify-center items-center min-h-screen">
      {loading ? (
        <ClipLoader color={"#E21D3C"} loading={loading} size={100} />
      ) : (
        <div>
          <div className="flex flex-col max-w-sm md:max-w-max md:w-4/5 mx-auto px-3 py-10 space-y-10">
            <div className="w-full flex flex-col md:flex-row md:items-center gap-y-10">
              <div className="flex flex-col mx-auto items-center md:gap-y-5 space-y-2">
                <img className="w-32 h-32" src={logo} alt="WTM logo" />
                <p className="text-xl md:text-3xl font-inter font-bold">
                  What's the Move?
                </p>
                <p className="font-inter md:text-xl">Make life interesting.</p>
              </div>
              <div className="flex mx-auto items-center">
                <img className="w-64" src={ipad} alt="WTM imazh" />
              </div>
            </div>

            <h1 className="md:ml-12 lg:ml-24 text-4xl font-bold">
              Problem Space
            </h1>
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-x-14 md:mx-12 lg:mx-24 gap-y-5">
              <div className="space-y-5">
                <div className="space-y-5">
                  <h1 className="text-2xl font-bold">Current Situation</h1>
                  <p className="text-sm">
                    In today’s fast changing times, too many events are being
                    held periodically and there is no easily accessible
                    information on the internet. We often end up missing
                    information about important events being held near us, such
                    as community organizations, outdoor events, activisms and
                    more. This information is being published in different
                    places. Accessing this information easily is crucial for
                    businesses and communities to explore and have a better
                    reach within their preferences.
                  </p>
                </div>
                <div className="space-y-5">
                  <h1 className="text-2xl font-bold">Competitive Research</h1>
                  <div className="text-sm">
                    Often businesses and community organizations have an online
                    presence which allows them to have a better reach to the
                    people which requires them to update the content and
                    marketing regularly to be more effective. However, this
                    doesn’t necessarily guarantee a higher reach since the user
                    base is divided into many different sources online, so
                    either they must be regular followers or be notified
                    regularly of upcoming events. In the digital information
                    era, there is a ton of websites where people can navigate to
                    find the event they are looking for, but this overwhelming
                    information sure has its negative effects making it complex
                    and increasing the effort to find points of interest without
                    the necessary filters and tools.
                    <ul className="list-disc pl-4 pt-4 space-y-2">
                      <li>
                        Event websites like “Eventbrite” do not have proximity
                        in mind.
                      </li>
                      <li>
                        Competitors do not learn from people preferences by the
                        events they attend to
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="space-y-5">
                <h1 className="text-2xl font-bold">Insights</h1>
                <p className="text-sm">
                  While not having a solution for the current situation here are
                  some of the major problems people run into every day:
                  <div className="list-disc pl-4 pt-4 space-y-2">
                    <li>
                      Too much information in news portals makes it hard to scan
                      and discover interests.
                    </li>
                    <li>
                      Information is not easily accessible, and many portals
                      require registration.
                    </li>
                    <li>
                      Often organizers create a social media page for each
                      event, and keeping up with them takes a lot of time and
                      effort.
                    </li>
                    <li>Many portals lack precise location data for events.</li>
                    <li>
                      Important events are scheduled much in advance, and often
                      there is no way of reminding people when the event date is
                      approaching.
                    </li>
                    <li>
                      In some cases, there is a lack of filtering for events and
                      activities.
                    </li>
                    <li>
                      In most cases, lifestyles and personal preferences are not
                      really taken into consideration.
                    </li>
                  </div>
                </p>
              </div>
            </div>

            <h1 className="md:ml-12 lg:ml-24 text-4xl font-bold">
              Solution Space
            </h1>
            <div className="space-y-5 md:gap-x-12 md:mx-12 lg:mx-24">
              <h1 className="text-2xl font-bold">Proof of Concept</h1>
              <p className="text-sm">
                While keeping in mind such findings through complaints, we tried
                to design a platform that takes into consideration these pain
                points and supports four main pillars: Discovery, Accessibility,
                Community and Sustainability. We built a platform which would
                easily bring together businesses and users by solving these
                problems into one easily accessible tool.
              </p>
            </div>
            <div className="space-y-5 md:gap-x-12 md:mx-12 lg:mx-24">
              <div className="flex flex-col lg:flex-row">
                <div className="lg:w-1/2 lg:space-y-10">
                  <h1 className="text-2xl font-bold">Results</h1>
                  <p className="text-sm">
                    The purpose of What’s the move is to explore events in your
                    city, support businesses and build a culture rooted in
                    social and activity connections. The platform accomplishes
                    its purpose by supporting four main pillars:
                  </p>
                  <ul className="list-disc pl-4 pt-4 space-y-10">
                    <li>
                      <h3 className="text-base">Discoverability</h3>
                      <p className="text-sm">
                        As the very first step of a person who is trying to
                        explore, discovery is fundamental in a smooth user
                        experience. By taking into consideration human desires
                        and preferences according to their needs and their
                        lifestyles, we designed a more user centered experience,
                        by humanizing filters.
                      </p>
                    </li>
                    <li>
                      <h3 className="text-base">Accessibility</h3>
                      <p className="text-sm">
                        WTM? is proving that technology can make data accessible
                        for businesses, advertising accessible to grassroots
                        organizations, and events, resources, and joy accessible
                        to communities.
                      </p>
                    </li>
                    <li>
                      <h3 className="text-base">Community</h3>
                      <p className="text-sm">
                        A community is more than just an area or a location;
                        it’s the people, history, and culture that give it life.
                        Whether it’s the block you grew up on, or the social
                        group you found yourself in, our platform shines a light
                        on the fact that community is essential to all our
                        lives.
                      </p>
                    </li>
                    <li>
                      <h3 className="text-base">Sustainability</h3>
                      <p className="text-sm">
                        WTM? is imagining a world where ‘everybody eats.’ It
                        believes that when it creates a virtuous cycle of
                        success for everyone, it can establish strong,
                        self-sustaining, and vibrant communities. Intends to
                        give its users the best experience by offering a wide
                        range of features such as exploring moves by popularity,
                        moves near you by using live location or address used on
                        sign up, moves for you which takes care of your
                        preferences and smartly tells you what moves you might
                        like, a search tool where you can explore the moves, a
                        reward section where you can receive rewards by giving
                        support etc...
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="lg:w-1/2 flex justify-center">
                  <img src={results} alt="Wtm? Result" />
                </div>
              </div>
            </div>

            <div className="space-y-5">
              <h1 className="text-2xl font-bold text-center">
                Technology Stack
              </h1>
              <ul className="font-inter text-center">
                <li>AWS Appsync</li>
                <li>AWS Amplify</li>
                <li>ReactJS</li>
                <li>AWS S3</li>
                <li>AWS Lambdas</li>
                <li>AWS Cognito</li>
                <li>Elastic Search</li>
                <li>Apache Airflow</li>
                <li>Javascript</li>
                <li>Python</li>
                <li>AWS Cloudfront + Lambda at edge</li>
                <li>Machine learning algorithms for price prediction</li>
                <li>Telegram bots for notification</li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default WTM;
