import React from "react";
import wtm from "../Images/WTMCase.svg";
import digidog from "../Images/digidoc.svg";
import kaktu from "../Images/kaktu.svg";
import oreksi from "../Images/oreksi.svg";
import stoli from "../Images/stoli.svg";

const Works = React.forwardRef<HTMLDivElement, {}>((_props, ref) => {
  return (
    <div ref={ref} className="w-full flex flex-col justify-center items-center">
      <div className="px-12 md:px-0 md:w-3/5 justify-center items-center flex flex-row">
        <div className="flex flex-col md:items-center space-y-5 pt-10">
          <h1 className="text-3xl md:text-5xl text-white font-domine">
            Recent Works
            <span className="text-7xl text-red-600">.</span>
          </h1>
          <p className="text-white ">
            These are some short descriptions of the cases we’ve covered in the
            recent past.
          </p>
          <div className="w-full flex justify-center text-white space-y-5">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 justify-center">
              {/* Cards */}
              <div className="card-container">
                <div className="card-flex">
                  <div className="space-y-5">
                    <img
                      src={digidog}
                      alt="digidoc"
                      className="cursor-default w-20"
                    />
                    <div className="space-y-2">
                      <p className="text-2xl">Digidoc</p>
                      <p className="text-xs font-inter">
                        Online Doctor Consulting App. An effortless way to keep
                        track of your health.
                      </p>
                    </div>
                    <p className="text-xs italic underline text-zinc-400 font-inter">
                      Read the Case
                    </p>
                  </div>
                </div>
              </div>
              <div className="card-container">
                <div className="card-flex">
                  <div className="space-y-5">
                    <a
                      href="/wtm"
                      target="_blank"
                      className="inline-block cursor-default"
                    >
                      <img
                        src={wtm}
                        alt="wtm"
                        className="h-20 w-20 rounded-2xl cursor-pointer"
                      />
                    </a>
                    <div className="space-y-2">
                      <p className="text-2xl">WTM?</p>
                      <p className="text-xs font-inter">
                        One stop shop for the best moves to make in your city.
                      </p>
                    </div>
                  </div>
                  <p className="text-xs italic underline text-zinc-400 font-inter">
                    <a href="/wtm" target="_blank">
                      Read the Case
                    </a>
                  </p>
                </div>
              </div>
              <div className="card-container">
                <div className="card-flex">
                  <div className="space-y-5">
                    <a
                      href="https://stoli.app"
                      target="_blank"
                      className="inline-block cursor-default"
                    >
                      <img
                        src={stoli}
                        alt="stoli"
                        className="h-20 w-20 rounded-2xl cursor-pointer"
                      />
                    </a>
                    <div className="space-y-2">
                      <p className="text-2xl">Stoli</p>
                      <p className="text-xs font-inter">
                        An ongoing project centered around the design of a
                        venue.
                      </p>
                    </div>
                  </div>
                  <p className="text-xs italic underline text-zinc-400 font-inter">
                    <a href="https://stoli.app" target="_blank">
                      Read the Case
                    </a>
                  </p>
                </div>
              </div>
              <div className="card-container">
                <div className="card-flex">
                  <div className="space-y-5">
                    <a
                      href="/kaktu"
                      target="_blank"
                      className="inline-block cursor-default"
                    >
                      <img
                        src={kaktu}
                        alt="kaktu"
                        className="h-20 w-20 rounded-2xl cursor-pointer"
                      />
                    </a>
                    <div className="space-y-2">
                      <p className="text-2xl">Ka’Ktu</p>
                      <p className="text-xs font-inter">
                        A central place where people can search for renting or
                        buying a home.
                      </p>
                    </div>
                  </div>
                  <p className="text-xs italic underline text-zinc-400 font-inter">
                    <a href="/kaktu" target="_blank">
                      Read the Case
                    </a>
                  </p>
                </div>
              </div>
              <div className="card-container">
                <div className="card-flex">
                  <div className="space-y-5">
                    <a
                      href="/oreksi"
                      target="_blank"
                      className="inline-block cursor-default"
                    >
                      <img
                        src={oreksi}
                        alt="kaktu"
                        className="h-20 w-20 rounded-2xl cursor-pointer"
                      />
                    </a>
                    <div className="space-y-2">
                      <p className="text-2xl">Oreksi</p>
                      <p className="text-xs font-inter">
                        Food Delivery that cares.
                      </p>
                    </div>
                  </div>
                  <p className="text-xs italic underline text-zinc-400 font-inter">
                    <a href="/oreksi" target="_blank">
                      Read the Case
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Works;
