import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAvbGGJ-GRah9FEnfR19DE7slCwJo_mon4",
  authDomain: "better-contact-b421b.firebaseapp.com",
  databaseURL: "https://better-contact-b421b.firebaseio.com",
  projectId: "better-contact-b421b",
  storageBucket: "better-contact-b421b.appspot.com",
  messagingSenderId: "579369958399",
  appId: "1:579369958399:web:66255696dea6a78a7645ca"
};

const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);

