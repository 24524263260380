import { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import concept from "../../Images/conceptKaktu.svg";
import logo from "../../Images/kaktuBig.svg";
import ipad from "../../Images/kaktuiPad.svg";
import result from "../../Images/resultsKaktu.svg";
import schemaKaktu from "../../Images/schemaKaktu.svg";

function Kaktu() {
  const [loading, setLoading]: [boolean, (value: (((prevState: boolean) => boolean) | boolean)) => void] = useState(false);
  useEffect((): void => {
    setLoading(true);
    setTimeout((): void => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <div className="flex justify-center items-center min-h-screen">
      {loading ? (
        <ClipLoader color={"#E21D3C"} loading={loading} size={100} />
      ) : (
        <div>
          <div className="flex flex-col max-w-sm md:max-w-max md:w-4/5 mx-auto px-3 md:px-0 py-10 space-y-10">
            <div className="md:ml-12 lg:ml-24 flex md:mx-20 flex-col md:flex-row pt-10 gap-y-10">
              <div className="md:w-1/3 flex flex-col">
                <img className="w-32" src={logo} alt="WTM logo" />
                <p className="font-inter">
                  The one stop shop to find a living place.
                </p>
              </div>
              <div className="md:w-2/3">
                <img className="" src={ipad} alt="WTM imazh" />
              </div>
            </div>

            <h1 className="md:ml-12 lg:ml-24 text-4xl font-bold">
              Problem Space
            </h1>
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-x-14 md:mx-12 lg:mx-24 gap-y-5">
              <div className="space-y-5">
                <div className="space-y-5">
                  <h1 className="text-2xl font-bold">Current Situation</h1>
                  <p className="text-sm">
                    In the digital information era, there is an ocean of
                    websites where people can navigate to look for the right
                    house to buy or rent. This abundance of websites fragmented
                    the market in various sources, making it complex for people
                    to discover opportunities.
                  </p>
                </div>
                <div className="space-y-5">
                  <h1 className="text-2xl font-bold">Competitive Research</h1>
                  <div className="text-sm">
                    Well established real estate agencies have a strong presence
                    which allows people to search for a home in a specified area
                    and filter them by common indicators such as price, surface,
                    number of rooms and amenities.
                    <ul className="list-disc pl-4 pt-4 space-y-2">
                      <li>
                        In most cases the suggested homes were randomly selected
                        and indicated a lower price for the property, but
                        without taking into consideration the person’s
                        preferences.
                      </li>
                      <li>
                        No precise location. The location was described in
                        words, and shown in the map as an area.
                      </li>
                      <li>
                        There was also no option to get notified if any new
                        property with specified criteria could become available.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="space-y-5">
                <h1 className="text-2xl font-bold">Insights</h1>
                <div className="text-sm">
                  By performing interviews with people who recently found a home
                  for rent and also those who were actually searching for a
                  home, we found out some interesting insights:
                  <ul className="list-disc pl-4 pt-4 space-y-2">
                    <li>
                      People searched for rental homes in a couple of real
                      estate platforms and public portals which takes e lot of
                      time effort. (6-12 websites average)
                    </li>
                    <li>
                      There is no visual selection of the area, increasing
                      complexity for choosing the preferred areas.
                    </li>
                    <li>
                      Good opportunities seem to be taken very quickly after
                      they got published.
                    </li>
                    <li>It’s hard to understand if the price is fair.</li>
                    <li>In many cases locations were wrong.</li>
                    <li>
                      Some data were hard to understand, requiring phone calls
                      to agencies for explanation.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <h1 className="md:ml-12 lg:ml-24 text-4xl font-bold">
              Solution Space
            </h1>
            <div className="space-y-5 md:gap-x-12 md:mx-12 lg:mx-24">
              <div className="flex flex-col lg:flex-row space-y-5">
                <div className="lg:w-1/3 space-y-5 lg:space-y-10">
                  <h1 className="text-2xl font-bold">Proof of Concept</h1>
                  <p className="text-sm">
                    We had to solve the location problem, the lack of
                    consistency in data, the lack of a visual selection in the
                    map, price comparisons, add a notification feature that
                    respects peoples preferences, and the use of a consistent
                    language for amenities. Fist we collected the data in a
                    temporary repository, to standardise and structure them in a
                    consistent way. We didn’t have any Albanian natural language
                    processing at hand, so we used google translate as a
                    workaround to validate the idea.
                  </p>
                  <p className="text-sm">
                    It worked just as expected. We actually ended up having more
                    meaningful information about the property than the source
                    itself.
                  </p>
                  <p className="text-sm">
                    Those data helped feed our machine learning algorithm,
                    suggest people the fairness of the price and have
                    consistency in presenting the data.
                  </p>
                </div>
                <div className="md:w-2/3 mx-auto flex justify-center">
                  <img
                    className=""
                    src={concept}
                    alt="Oreksi Proof of Concept"
                  />
                </div>
              </div>
            </div>
            <div className="space-y-5 md:gap-x-12 md:mx-12 lg:mx-24">
              <div className="flex flex-col lg:flex-row space-y-5">
                <div className="lg:w-1/3 space-y-5 lg:space-y-10">
                  <h1 className="text-2xl font-bold">Results</h1>
                  <p className="text-sm">
                    After some iterations and testings with real users, we
                    created a platform that offers the following:
                  </p>
                  <ul className="list-disc pl-4 pt-4 ">
                    <li>
                      <p className="text-sm">
                        A central place where people can search for homes to
                        rent or purchase.
                      </p>
                    </li>
                    <li>
                      <p className="text-sm">
                        Enabled people to search in preferred locations visually
                        by drawing on the map.
                      </p>
                    </li>
                    <li>
                      <p className="text-sm">
                        Enabled people to get notified when any property that
                        matched their preferences became available.
                      </p>
                    </li>
                    <li>
                      <p className="text-sm">
                        Created a score system to suggest the fairness of the
                        price comparing it to hundreds of other properties with
                        similar amenities.
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="md:w-2/3 mx-auto flex justify-center">
                  <img className="" src={result} alt="Oreksi Result" />
                </div>
              </div>
            </div>
            <div className="mx-auto">
              <img className="" src={schemaKaktu} alt="Oreksi Schema" />
            </div>

            <div className="space-y-10">
              <h1 className="text-2xl font-bold text-center">
                Technology Stack
              </h1>
              <ul className="font-inter text-center">
                <li>AWS Appsync</li>
                <li>AWS Amplify</li>
                <li>ReactJS</li>
                <li>AWS S3</li>
                <li>AWS Lambdas</li>
                <li>AWS Cognito</li>
                <li>Elastic Search</li>
                <li>Apache Airflow</li>
                <li>Javascript</li>
                <li>Python</li>
                <li>AWS Cloudfront + Lambda at edge</li>
                <li>Machine learning algorithms for price prediction</li>
                <li>Telegram bots for notification</li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default Kaktu;
