import React from "react";
import whatchart from "../Images/whatchart.svg";

const What = React.forwardRef<HTMLDivElement, {}>((_props, ref) => {
  return (
    <div
      ref={ref}
      className="w-full flex flex-col justify-center items-center gap-y-6 lg:flex-row"
    >
      <div className="px-12 md:px-0 md:w-3/5 flex flex-col lg:flex-row md:items-center  space-y-5">
        <div className="lg:w-1/2 lg:text-left pt-10">
          <h1 className="text-3xl md:text-5xl text-white mb-5">
            What we do
            <span className="text-7xl text-red-600">.</span>
          </h1>
          <p className="text-white font-inter">
            Today, technology is at the heart of every modern organisation. Our
            job is to make it more human, to build functional and beautiful
            digital products that have an impact on people’s lives, help them
            communicate with each other, easily navigate through intricacies,
            and solve their problems faster.
          </p>
          <br />
          <p className="text-white font-inter">
            To us is mostly about building connections and relationships between
            products and people, so we create moments of joy and pleasant
            surprises while getting people from A to B. Our goal is to create
            mutually satisfying relationships than can grow organically.
          </p>
        </div>
        <div className="lg:w-1/2">
          <img className="flex mx-auto" src={whatchart} alt="whatwedo" />
        </div>
      </div>
    </div>
  );
});

export default What;
