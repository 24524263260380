import React, { useState } from "react";
import logoBetter from "./../Images/logobetter.svg";
import bLogo from "./../Images/b-icon.svg";

interface MenuItemProps {
  item: {
    name: string;
    ref: string;
  };
  isActive: boolean;
  onClick: (ref: string) => void;
}

type SidebarProps = {
  onMenuItemClick: (item: string) => void;
  activeItem: string;
};

const MenuItem: React.FC<MenuItemProps> = ({ item, isActive, onClick }) => (
  <li
    className={`hover:translate-y-0.5 transition duration-200 hover:cursor-pointer hover:text-white  ${
      isActive ? "text-white" : "text-zinc-400"
    } group`}
    onClick={() => onClick(item.ref)}
  >
    <span className="">{item.name} ●</span>
  </li>
);

const Sidebar: React.FC<SidebarProps> = ({ onMenuItemClick, activeItem }) => {
  const menuItems = [
    { name: "Home", ref: "homeRef" },
    { name: "Who", ref: "whoRef" },
    { name: "What", ref: "whatRef" },
    { name: "How", ref: "howRef" },
    { name: "Works", ref: "worksRef" },
    { name: "Contact", ref: "contactRef" },
  ];

  const [isOpen, setIsOpen]: [
    boolean,
    (value: ((prevState: boolean) => boolean) | boolean) => void
  ] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  const handleMenuItemClick = (itemRef: string): void => {
    onMenuItemClick(itemRef);
    setIsOpen(false);

   
  };

  return (
    <div className="flex flex-col">
      <div className="h-20 hidden md:block justify-center mt-7 fixed"></div>
      <button
        id="toggleButton"
        className={`md:hidden fixed right-2 ${
          isOpen ? "open" : ""
        } z-50 hamburger focus:outline-none mt-6`}
        onClick={toggleMenu}
      >
        <span className="hamburger-top"></span>
        <span className="hamburger-middle"></span>
        <span className="hamburger-bottom"></span>
      </button>
      <div className="z-50 mt-2 fixed bg-transparent text-3xl font-bold text-white md:hidden">
        <button
          onClick={() => {
            onMenuItemClick("homeRef");
            setIsOpen(false);
          }}
          className="focus:outline-none"
        >
          <img className="w-10 h-10" src={bLogo} alt="" />
        </button>
      </div>
      <nav
        className={`${
          isOpen ? "flex" : "hidden"
        } fixed z-10 top-0 bottom-0 left-0 flex-col justify-center self-end w-full min-h-screen md:hidden py-1 pt-10 pl-8 space-y-10 text-lg text-white bg-black`}
      >
        {menuItems.map((item) => (
          <button
            key={item.ref}
            className="w-min cursor-pointer hover:text-pink-500"
            onClick={() => handleMenuItemClick(item.ref)}
          >
            {item.name}
          </button>
        ))}
      </nav>
      <div className="fixed hidden md:block">
        <div className="mt-10 cursor-pointer px-5 flex flex-row justify-between">
          <img
          className="hover:scale-105 transition duration-200"
            src={logoBetter}
            alt="better logo"
            onClick={() => onMenuItemClick("homeRef")}
          />
        </div>
        <div className="w-44 pr-5 space-y-32">
          <ul className="pt-40 text-zinc-400 space-y-8 text-right marker:text-white">
            {menuItems.map((item) => (
              <MenuItem
                key={item.ref}
                item={item}
                isActive={activeItem === item.ref}
                onClick={handleMenuItemClick}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
