import axios from "axios";
import { push as DBpush, ref as DBref, set as DBset } from "firebase/database";
import React, { useState } from "react";
import Swal from "sweetalert2";
import { db } from "../../firebase";
import logoBetter from "./../Images/logobetter.svg";
import "./Footer.css";

const Footer = React.forwardRef<HTMLDivElement, {}>((_props, ref) => {
  const [name, setName]: [
    string,
    (value: ((prevState: string) => string) | string) => void
  ] = useState("");
  const [email, setEmail]: [
    string,
    (value: ((prevState: string) => string) | string) => void
  ] = useState("");
  const [emailTouched, setEmailTouched] = useState(false);
  const [phone_number, setPhoneNumber]: [
    string,
    (value: ((prevState: string) => string) | string) => void
  ] = useState("");
  const [text, setMessage]: [
    string,
    (value: ((prevState: string) => string) | string) => void
  ] = useState("");
  const newContactRef = DBpush(DBref(db, "contacts"));
  const [isSending, setIsSending] = useState(false);

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    setIsSending(true);
    e.preventDefault();
    const data = {
      name,
      email,
      phone_number,
      text,
    };

    try {
      await DBset(newContactRef, {
        ...data,
        time: new Date(Date.now()).toLocaleString(),
      });
      await axios.post(
        "https://svzt2ejd3hezga2nod5i3f62pe0kpgaj.lambda-url.eu-west-1.on.aws",
        data
      );

      await new Promise((resolve) => setTimeout(resolve, 3000));

      setName("");
      setEmail("");
      setPhoneNumber("");
      setMessage("");

      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        background: "#dcfce7",
        showConfirmButton: false,
        cancelButtonColor: "#d33",
        timer: 3000,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });
      Toast.fire({
        icon: "info",
        iconColor: "#16a34a",
        title: "Your message has been sent successfully!",
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsSending(false);
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setEmailTouched(false);
  };

  const handleEmailBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setEmailTouched(true);
  };

  const validateEmail = (email: string) => {
    var re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  return (
    <div
      ref={ref}
      className="w-full flex flex-col justify-center items-center mt-20"
    >
      <div className="w-full md:h-screen px-12 md:px-0 md:w-3/5 justify-between flex flex-col text-white gap-y-6 gap-x-7">
        <div className="flex flex-col my-auto lg:flex-row space-y-5">
          <div className="w-full lg:w-1/2 flex flex-col items-center lg:items-end space-y-5 justify-center pt-10">
            <div className="w-full">
              <h1 className="text-3xl">Contact Us</h1>
              <p className="font-inter text-slate-300">
                Is there something on your mind? Let’s talk about it.
              </p>
            </div>
          </div>
          <div className="lg:w-1/2 flex justify-center lg:justify-end">
            <div className="w-full lg:max-w-md px-8 py-8 lg:px-8 lg:py-8 flex flex-col space-y-4 bg-[#343434] rounded-2xl">
              <input
                type="text"
                id="name"
                min={2}
                max={50}
                required
                placeholder="Full name*"
                className="bg-[#202020] h-10 rounded border border-[#636363] px-4 py-2 focus:outline-none focus:border-none focus:bg-[#000]"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <div>
                <input
                  type="email"
                  id="email"
                  required
                  placeholder="Email Address*"
                  className="w-full bg-[#202020] h-10 rounded border border-[#636363] px-4 py-2 focus:outline-none focus:border-none focus:bg-[#000]"
                  value={email}
                  onChange={handleEmailChange}
                  onBlur={handleEmailBlur}
                />
                {emailTouched && email && !validateEmail(email) && (
                  <p className="text-xs text-red-500">
                    Invalid e-mail address!
                  </p>
                )}
              </div>
              <input
                minLength={8}
                maxLength={15}
                type="number"
                id="phone_number"
                placeholder="Phone Number (Optional)"
                className="bg-[#202020] h-10 rounded border border-[#636363] px-4 py-2 focus:outline-none focus:border-none focus:bg-[#000] box-border"
                value={phone_number}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              <textarea
                id="text"
                minLength={10}
                required
                placeholder="Tell us more*"
                className="h-44 bg-[#202020] rounded border border-[#636363] px-4 py-2 focus:outline-none focus:border-none focus:bg-[#000]"
                value={text}
                onChange={(e) => setMessage(e.target.value)}
              />
              <button
                type="button"
                className="w-full sm:w-24 h-10 bg-red-600 text-white px-4 py-2 font-inter rounded shadow-lg transition duration-200 ease-in-out mt-5 hover:bg-red-700 disabled:bg-red-500 disabled:cursor-not-allowed disabled:opacity-70"
                title={
                  !name || !email || !text
                    ? "Please fill in all required fields."
                    : ""
                }
                disabled={!name || !email || !text || isSending}
                onClick={handleSubmit}
              >
                {isSending ? (
                  <svg
                    className="animate-spin h-5 w-5 mx-auto"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  "Send"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <div className="w-full md:mb-10 flex flex-row justify-between items-center px-12 md:px-0 py-5 md:-ml-[138px] md:py-0">
        <div className="w-20">
          <img src={logoBetter} alt="better." />
        </div>
        <div>
          <p className="text-white text-xs">
            © {new Date().getFullYear()} better. All rights reserved.{" "}
          </p>
        </div>
      </div>
    </div>
  );
});

export default Footer;
