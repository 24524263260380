import React from "react";
import pen from "../Images/pen.svg";
import animation from "./../../assets/animations/8zCmWXSjrl.json";
import Lottie from "react-lottie";

const Home = React.forwardRef<HTMLDivElement, {}>((_props, ref) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div ref={ref} className="radial-gradient flex justify-center items-center">
      <h1 className="text-xl sm:text-3xl xl:text-5xl text-white xl:space-y-3">
        <div>
          From insights to viability
          <span className="text-3xl md:text-5xl xl:text-7xl text-red-600">
            .
          </span>
        </div>
        <div className="flex flex-col">
          <div className="">
            Creating digital solutions for
            <span className="inline-flex items-center ml-2">
              {" "}
              <span>humans</span>
            </span>
            <span className="text-3xl md:text-5xl xl:text-7xl text-red-600">
              .
            </span>
          </div>
          <div className="flex justify-end">
            <img src={pen} alt="pen" className="w-20 sm:w-36 xl:w-56" />
          </div>
        </div>
        <div className="hidden md:block py-16">
          <Lottie options={defaultOptions} height={50} width={50} />
        </div>
      </h1>
    </div>
  );
});

export default Home;
