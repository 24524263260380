import React from "react";
import whoweare from "../Images/whoweare.svg";

const Who = React.forwardRef<HTMLDivElement, {}>((_props, ref) => {
  return (
    <div
      ref={ref}
      className="w-full flex flex-col justify-center items-center gap-y-6 lg:flex-row"
    >
      <div className="px-12 md:px-0 md:w-3/5 flex flex-col lg:flex-row md:items-center space-y-5">
        <div className="lg:w-1/2 lg:text-left pt-10">
          <h1 className="text-3xl md:text-5xl text-white mb-5">
            Who we are
            <span className="text-7xl text-red-600">.</span>
          </h1>
          <p className="text-white font-inter">
            We are a group of curious people who love diversity and are thrilled
            by challenges. We use our brains, and some magic tools to create
            products and experiences that solve problems and seize
            opportunities.
          </p>
          <br />
          <p className="text-white font-inter">
            We believe in each other’s authenticity, so the best work happens
            when anybody can speak up about anything. We don’t stop at what we
            already know and we are not afraid of the unknown. Practically
            that’s how we grow. We challenge ourselves and enjoy giving people
            better than they knew they wanted, and that drives our motivation.
            We have an addiction for quality. Our designers minimize friction in
            every interaction, and our developers build fast and scalable
            solutions.
          </p>
        </div>
        <div className="lg:w-1/2">
          <img className="flex mx-auto" src={whoweare} alt="whoweare" />
        </div>
      </div>
    </div>
  );
});

export default Who;
