import { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import logo from "./../../Images/OreksiHeadLogo.svg";
import ipad from "./../../Images/OreksiHeaderLogo.svg";
import concept from "./../../Images/OreksiProofOfConcept.svg";
import result from "./../../Images/OreksiResults.svg";
import schema from "./../../Images/OreksiSchema.svg";

function Oreksi() {
  const [loading, setLoading]: [boolean, (value: (((prevState: boolean) => boolean) | boolean)) => void] = useState(false);
  useEffect((): void => {
    setLoading(true);
    setTimeout((): void => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <div className="flex justify-center items-center min-h-screen">
      {loading ? (
        <ClipLoader color={"#E21D3C"} loading={loading} size={100} />
      ) : (
        <div>
          <div className="flex flex-col max-w-sm md:max-w-max md:w-4/5 mx-auto px-3 md:px-0 py-10 space-y-10">
            <div className="w-full flex flex-col md:flex-row md:items-center gap-y-10">
              <div className="flex flex-col mx-auto items-center md:gap-y-5 space-y-2">
                <img className="w-32 h-32" src={logo} alt="WTM logo" />
                <p className="text-xl md:text-3xl font-inter font-bold">
                  Oreksi
                </p>
                <p className="font-inter md:text-xl">
                  Food delivery that cares.
                </p>
              </div>
              <div className="flex mx-auto items-center">
                <img className="w-64" src={ipad} alt="WTM imazh" />
              </div>
            </div>

            <h1 className="md:ml-12 lg:ml-24 text-4xl font-bold">
              Problem Space
            </h1>
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-x-14 md:mx-12 lg:mx-24 gap-y-5">
              <div className="space-y-5">
                <div className="space-y-5">
                  <h1 className="text-2xl font-bold">Current Situation</h1>
                  <p className="text-sm">
                    In the digital information era, there is an ocean of
                    websites where people can navigate to look for the right
                    house to buy or rent. This abundance of websites fragmented
                    the market in various sources, making it complex for people
                    to discover opportunities.
                  </p>
                </div>
                <div className="space-y-5">
                  <h1 className="text-2xl font-bold">Competitive Research</h1>
                  <div className="text-sm">
                    We studied 3 major food delivery apps to see their approach
                    in this service. None of them had a real delivery time
                    estimation, the estimation was a simple average.
                    <ul className="list-disc pl-4 pt-4 space-y-2">
                      <li>
                        The menus, did not take into consideration allergies.
                      </li>
                      <li>
                        There was no easy way to remove or add ingredients when
                        ordering more than one dish.
                      </li>
                      <li>
                        In cases of wrong order, there was no way of notifying
                        the restaurant through the app.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="space-y-5">
                <h1 className="text-2xl font-bold">Insights</h1>
                <p className="text-sm">
                  We observed and inteviewed people who used these apps
                  frequently, and the delivery guys who worked for the
                  companies. This were the insights we discovered:
                  <div className="list-disc pl-4 pt-4 space-y-2">
                    <li>
                      People couldn’t filter their favourite dishes accross
                      restaurants.
                    </li>
                    <li>
                      The suggestions offered in the platform did not match
                      people’s preferences in foods.
                    </li>
                    <li>
                      When people received the wrong order, the only way to fix
                      the order, was to call the support and make a complaint.
                    </li>
                    <li>
                      Adding or removing ingredients from dishes could be done
                      only by writing a note, making it easy to missunderstand
                      from the cookers.
                    </li>
                    <li>
                      People who pay electronically wished there was a way to
                      tip the deliverer in the app without using cash.
                    </li>
                    <li>
                      The delivery time estimation almost never matches reality.
                      Sometimes the delivery takes twice the time.
                    </li>
                    <li>
                      Delivery guys had a hard time calculating the more
                      efficient path.
                    </li>
                  </div>
                </p>
              </div>
            </div>

            <h1 className="md:ml-12 lg:ml-24 text-4xl font-bold">
              Solution Space
            </h1>
            <div className="space-y-5 md:gap-x-12 md:mx-12 lg:mx-24">
              <div className="flex flex-col lg:flex-row space-y-5">
                <div className="lg:w-1/3 space-y-5 lg:space-y-10">
                  <h1 className="text-2xl font-bold">Proof of Concept</h1>
                  <p className="text-sm">
                    We started working with the concept of ingredients. This
                    approach would help the platform understant people’s food
                    preferences, stay on track with their fitness program, and
                    also suggest new dishes with their favourite elements.By
                    using ingredients as a dish element, people can also remove
                    or add them much easier.
                  </p>
                  <p className="text-sm">
                    Then we focused on the delivery component and designed a
                    live tracking system which updates, the delivery location
                    every minute, considers traffic (from google map) for a more
                    precise time estimation.We also experimented with “shortest
                    route” to help delivery guys be more efficient with their
                    deliveries.
                  </p>
                  <p className="text-sm">
                    In the order feedback mechanism, we designed the tip option
                    for the delivery guy, which allows people to tip in cash, or
                    through the app.
                  </p>
                </div>
                <div className="md:w-2/3 mx-auto flex justify-center">
                  <img
                    className=""
                    src={concept}
                    alt="Oreksi Proof of Concept"
                  />
                  {/* <img className="w-96" src={schema} alt="Oreksi Schema" /> */}
                </div>
              </div>
            </div>
            <div className="space-y-5 md:gap-x-12 md:mx-12 lg:mx-24">
              <div className="flex flex-col lg:flex-row space-y-5">
                <div className="lg:w-1/3 space-y-5 lg:space-y-10">
                  <h1 className="text-2xl font-bold">Results</h1>
                  <p className="text-sm">
                    We tested a couple of prototypes with people who use food
                    delivery apps frequently, and sticked with the veriant that
                    they preferred.As a result we build a platform and an
                    aplication which solves the following painpoints.
                  </p>
                  <ul className="list-disc pl-4 pt-4 ">
                    <li>
                      <p className="text-sm">
                        Allows people to search for dishes in cross
                        restaturants.
                      </p>
                    </li>
                    <li>
                      <p className="text-sm">
                        Allow people to add their allergies in their profile, so
                        that ingredients of allergenic elements were
                        automatically filtered from the dishes.
                      </p>
                    </li>
                    <li>
                      <p className="text-sm">
                        Enabled people to filter dishes accoring to their diets
                        and keep on track with their fitness recommendations.
                      </p>
                    </li>
                    <li>
                      <p className="text-sm">
                        Enabled people to track the delivery, with a more
                        precise time estimation and almost live tracking.
                      </p>
                    </li>
                    <li>
                      <p className="text-sm">
                        Enabled people to return the order easily if there was a
                        missmatch.
                      </p>
                    </li>
                    <li>
                      <p className="text-sm">
                        Helped delivery guys find the shourtest route to deliver
                        their orders.
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="md:w-2/3 mx-auto flex justify-center">
                  <img className="" src={result} alt="Oreksi Result" />
                </div>
              </div>
            </div>
            <div className="mx-auto">
              <img className="" src={schema} alt="Oreksi Schema" />
            </div>

            <div className="space-y-10">
              <h1 className="text-2xl font-bold text-center">
                Technology Stack
              </h1>
              <ul className="font-inter text-center">
                <li>AWS Appsync</li>
                <li>AWS Amplify</li>
                <li>ReactJS</li>
                <li>AWS S3</li>
                <li>AWS Lambdas</li>
                <li>AWS Cognito</li>
                <li>Elastic Search</li>
                <li>Apache Airflow</li>
                <li>Javascript</li>
                <li>Python</li>
                <li>AWS Cloudfront + Lambda at edge</li>
                <li>Machine learning algorithms for price prediction</li>
                <li>Telegram bots for notification</li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default Oreksi;
