import React from "react";

const How = React.forwardRef<HTMLDivElement, {}>((_props, ref) => {
  return (
    <div ref={ref} className="w-full flex flex-col justify-center items-center">
      <div className="px-12 md:px-0 md:w-3/5 flex flex-col md:items-center space-y-5 pt-10">
        <h1 className="text-3xl md:text-5xl text-white">
          How we do it
          <span className="text-7xl text-red-600">.</span>
        </h1>
        <p className="text-white font-inter md:text-center">
          It’s important for us to have a process in place to do great work, but
          in reality projects can be so different that sticking to a specific
          formula might not make sense. Sometimes we mix and match the pieces,
          and sometimes we create completely new components for the process.
          Either way these are some of the most common components of our
          process.
        </p>
        <div className="space-y-16">
          <div className="step-card"></div>
          <div className="bg-zinc-800 rounded-lg md:rounded-2xl text-white space-y-5">
            <div className="grid grid-cols-1 p-7 sm:grid-cols-2 lg:grid-cols-3 gap-4 justify-center">
              <div className="space-y-3">
                <h1 className="text-2xl">1. Dive in</h1>
                <p className="font-inter leading-tight">
                  In the beginning we take in all there is to know about the
                  project, the product and the people we’re working with,
                  approaching work with clients as a partnership. This is the
                  part where we open all the cards and put them on the table. We
                  align all the dreams, excitements and things that might cause
                  concern.
                </p>
              </div>
              <div className="space-y-3">
                <h1 className="text-2xl">2. Strategy</h1>
                <p className="font-inter leading-tight">
                  Strategy is the phase where we articulate a point of view on
                  what is this project about, and it lays the foundation upon
                  which everything is built on. This is the part where we try to
                  understand the competition and the people we’re designing for,
                  through persona research and outlining the different types of
                  users.
                </p>
              </div>
              <div className="space-y-3">
                <h1 className="text-2xl">3. Ideation</h1>
                <p className="font-inter leading-tight">
                  Once the fundamentals are right in place, and everyone is on
                  the same page, we start with sketching. This is the time when
                  constraints do not exist and anyone can dream big. The more
                  the better. Any strange, weird, extravagant or odd ideas are
                  very welcome at this time. The goal here is strictly the core
                  potential.
                </p>
              </div>
              <div className="space-y-3">
                <h1 className="text-2xl">4. Testing</h1>
                <p className="font-inter leading-tight">
                  Once the concepts are defined, it’s time to put these concept
                  prototypes to the test in front of real people. We start with
                  a big push for the early concepts, and then continue with
                  several iterations as the prototypes are further improved and
                  polished.
                </p>
              </div>
              <div className="space-y-3">
                <h1 className="text-2xl">5. Creation</h1>
                <div className="font-inter leading-tight">
                  In the creation phase, it’s time to put the ideas into an
                  understandable system, and get the rest of details right.
                  <ul className="list-disc pl-4 pt-2">
                    <li>How is the system organised?</li>
                    <li>What details will be in every page or section?</li>
                    <li>
                      How do these details vary from new users to existing ones?
                    </li>
                  </ul>
                </div>
              </div>
              <div className="space-y-3">
                <h1 className="text-2xl">6. Development</h1>
                <p className="font-inter leading-tight">
                  We don’t outsource any part of the development. Everything is
                  done in-house by people that are used to work together. Our
                  developers are eager to tackle on new technologies and find
                  new ways of solving problems. This has allowed us to grow our
                  technology stacks quickly.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default How;
