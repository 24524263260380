import React, { useEffect, useRef, useState } from "react";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import Sidebar from "../navigation/Sidebar";
import Footer from "./Footer";
import Home from "./Home";
import How from "./How";
import What from "./What";
import Who from "./Who";
import Works from "./Works";

function HomePage() {
  type ComponentRefName =
    | "homeRef"
    | "whoRef"
    | "whatRef"
    | "howRef"
    | "worksRef"
    | "contactRef";

  const homeRef = useRef<HTMLDivElement>(null);
  const whoRef = useRef<HTMLDivElement>(null);
  const whatRef = useRef<HTMLDivElement>(null);
  const howRef = useRef<HTMLDivElement>(null);
  const worksRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);

  const scrollToComponent = (item: string, block: any = 'start'): void => {
    const refMap: Record<
      ComponentRefName,
      React.RefObject<HTMLDivElement> | undefined
    > = {
      homeRef: homeRef,
      whoRef: whoRef as React.RefObject<HTMLDivElement>,
      whatRef: whatRef as React.RefObject<HTMLDivElement>,
      howRef: howRef as React.RefObject<HTMLDivElement>,
      worksRef: worksRef as React.RefObject<HTMLDivElement>,
      contactRef: contactRef as React.RefObject<HTMLDivElement>,
    };

    const componentRef = refMap[item as ComponentRefName];
    if (componentRef?.current) {
      componentRef.current.scrollIntoView({
        behavior: "smooth",
        block: block,
      });
    }
  };

  const [activeSection, setActiveSection]: [string, (value: (((prevState: string) => string) | string)) => void] = useState("homeRef");

  useEffect(() => {
    const handleScroll = () => {
      const sections = [homeRef, whoRef, whatRef, howRef, worksRef, contactRef];
      const currentScrollPosition: number = window.scrollY + window.innerHeight / 2;

      for (const sectionRef of sections) {
        const el = sectionRef.current;
        if (el) {
          const sectionTop: number = el.offsetTop;
          const sectionHeight: number = el.offsetHeight;
          if (
            currentScrollPosition >= sectionTop &&
            currentScrollPosition < sectionTop + sectionHeight
          ) {
            setActiveSection(
              sectionRef === homeRef
                ? "homeRef"
                : sectionRef === whoRef
                ? "whoRef"
                : sectionRef === whatRef
                ? "whatRef"
                : sectionRef === howRef
                ? "howRef"
                : sectionRef === worksRef
                ? "worksRef"
                : sectionRef === contactRef
                ? "contactRef"
                : ""
            );
            break;
          }
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <ReactScrollWheelHandler>
      <div className="bg-[#0F0F0F] min-h-screen">
        <Sidebar
          onMenuItemClick={(item) => {
            let blockType = item === 'howRef' ? 'start' : item === 'contactRef' ? 'end' : 'center';
            if (window.innerWidth < 768) {
              blockType = 'start';
            }
            scrollToComponent(item, blockType);
          }}
          activeItem={activeSection}
        />
        <div className="flex-1 space-y-10 md:ml-36 md:space-y-28">
          <div ref={homeRef}>
            <Home />
          </div>
          <div ref={whoRef}>
            <Who />
          </div>
          <div ref={whatRef}>
            <What />
          </div>
          <div ref={howRef}>
            <How />
          </div>
          <div ref={worksRef}>
            <Works />
          </div>
          <div ref={contactRef}>
            <Footer />
          </div>
        </div>
      </div>
    </ReactScrollWheelHandler>
  );
}

export default HomePage;
